import { FC } from 'react'

import styles from './MainNews.module.scss'
import {
  PostFragment,
  PostWithHtmlFragment,
} from '../../queries/generated/blog/schema'
import { PreviewPostFragment } from '../../queries/generated/blog/schema'
import PostMain from '../news/PostMain'
import PostNews from '../news/PostNews'
import Tags from '../news/Tags'
import Post from '../news/Post'
import { Tag } from '../../interfaces/Tags'
import cn from 'classnames'
import SpecialPost from '../news/SpecialPost'

interface IMainTop {
  articlesItems: PostFragment[]
  mainArticle: PostWithHtmlFragment
  newsItems: PreviewPostFragment[]
  className?: string
  articlesTags?: Pick<Tag, 'id' | 'name' | 'slug'>[]
  borderNone?: boolean
  priority?: boolean
  currentSpecial?: string
}

const MainNews: FC<IMainTop> = ({
  articlesItems,
  newsItems,
  className,
  articlesTags,
  borderNone,
  mainArticle,
  priority,
  currentSpecial,
}) => {
  return (
    <div
      className={cn(styles.grid, borderNone && styles.borderNone, className)}
    >
      <section className={styles.main}>
        {currentSpecial ? (
          <SpecialPost code={currentSpecial} />
        ) : (
          <PostMain data={mainArticle} priority={priority} />
        )}
      </section>
      <section className={styles.news}>
        <Tags
          tags={[
            {
              id: 'news',
              name: 'Новости',
              slug: 'news',
            },
          ]}
        />
        <ul className={styles.listNews}>
          {newsItems.slice(0, 6).map((news) => (
            <li key={news.id}>
              <PostNews data={news} priority={priority} />
            </li>
          ))}
        </ul>
      </section>
      <section className={styles.articles}>
        <Tags
          tags={
            articlesTags || [
              {
                id: 'st',
                name: 'Статьи',
              },
            ]
          }
        />
        <ul className={cn(styles.listArticles)}>
          {articlesItems.map((article) => (
            <li key={article.id}>
              <Post
                data={article}
                sizes="(max-width: 992px) 49vw, 245px"
                imageMaxWidth={175}
                className={styles.articlesCard}
                priority={priority}
              />
            </li>
          ))}
        </ul>
      </section>
      <div className={cn('main-news-line', styles.lineBottom)} />
    </div>
  )
}

export default MainNews
