import React, { FC } from 'react'
import StarIcon from '../../public/icons/special-star.svg'
import ArrowIcon from '../../public/icons/arrow-without-stick.svg'
import BracketIcon from '../../public/icons/bracket.svg'
import EyeIcon from '../../public/icons/eye.svg'
import styles from './SpecialPost.module.scss'
import cn from 'classnames'
import moc from '../special/moc'
import Image from '../common/Image'
import Link from 'next/link'
import { useDetailPageQuery } from '../../queries/generated/blog/schema'
import { BLOG_CLIENT } from '../../lib/constants'
import { numShrink } from '../../utils/numShrink'
import hasNonZeroHundreds from '../../utils/hasNonZeroHundreds'

const SpecialPost: FC<{ code: string }> = ({ code }) => {
  const { images, author, subtitle, title, readTime } = moc[code].mainPost

  const { data: pageData } = useDetailPageQuery({
    variables: { where: { slug: code } },
    context: BLOG_CLIENT,
  })

  return (
    <div className={styles.container}>
      <Image
        className={cn(styles.image, styles['image--desc'])}
        src={images.desc}
        fill
        alt="изображение для десктопа"
      />
      <Image
        className={cn(styles.image, styles['image--mobile'])}
        src={images.mobile}
        fill
        alt="изображение для телефона"
      />
      <div className={styles.top}>
        <div className={styles.tag}>
          <StarIcon />
          Exclusive
        </div>
        <div className={styles.brackets}>
          <BracketIcon />
          <BracketIcon />
        </div>
      </div>
      <div className={styles.content}>
        <Link
          href={`/${code}`}
          className={cn('specialHeading', 'ls-03', styles.title)}
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <p className={cn('specialSubtitle', 'ls-03', styles.subtitle)}>
          {subtitle}
        </p>
        <div className={styles.bottom}>
          <div className={cn('ls-03', styles.authorBlock)}>
            <div className={styles.author}>
              <Link href={`/author/${author.slug}`} title={author.name}>
                <Image
                  alt={`${author.name} фото`}
                  src={author.picture.url}
                  width={30}
                  height={30}
                  withOptimize
                />
              </Link>
              <Link
                href={`/author/${author.slug}`}
                title={author.name}
                className={styles.link}
              >
                <p>{author.name}</p>
              </Link>
            </div>
            {/* {pageData?.page?.pageviews > 999 && (
              <div className={styles.views}>
                <EyeIcon />{' '}
                {numShrink(
                  pageData.page.pageviews,
                  !hasNonZeroHundreds(pageData.page.pageviews)
                )}
              </div>
            )} */}
            <p className={styles.timeRead}>{pageData?.page?.readingTime}</p>
          </div>
          <Link className={cn('ls-03', styles.read)} href={`/${code}`}>
            Читать <ArrowIcon className={styles.arrow} />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default SpecialPost
